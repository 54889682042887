<template>
  <!-- <b-table bordered borderless stacked :items="items"></b-table> -->
  <div class="package-details-table bg-white overflow-hidden rounded-14">
    <b-row class="">
      <b-col
        sm="12"
        class="package-details-name"
      >
        <h1 class="package-details-table__title text-font-main text-bold-16">
          {{ $t('orders_page.employer_details_title') }}
        </h1>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body px-0">
      <b-col
        sm="12"
        class="package-details-value"
      >
        <div class="user-info mb-0 w-100">
          <div class="user-info__body">
            <div class="personal-info">
              <default-user-image-icon v-if="!photo" />
              <img
                v-else
                class="avatar"
                :src="photo.full_path + photo.file_name"
                alt=""
              >
              <p class="m-0 text-reg-16 text-font-main">
                {{ name }}
              </p>
            </div>
            <div class="d-flex">
              <router-link
                :to="{
                  name: 'users-business-details',
                  params: { lang: this.$i18n.locale, id },
                }"
                class="text-bold-14 text-font-main text_decoration"
                style="text-decoration: underline"
              >
                <span :class="'mx-0 '">{{ $t('common.show_details') }}</span>
                <!-- <add-circle-icon class="mx-2" /> -->
              </router-link>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import WarningModal from '@/components/WarningModal.vue';
import WameedForm from '@/components/WameedForm.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import TextArea from '../../TextArea.vue';

export default {
  components: {
    WameedBtn,
    WarningModal,
    ValidationObserver,
    TextArea,
    WameedForm,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    photo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showEmployeeRating: false,
    };
  },
  methods: {
    ...mapActions({}),
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
    openFile(data) {
      window.open(data.full_path + data.file_name, '_blank');
    },
  },
};
</script>
