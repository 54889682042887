<template>
  <b-form-group
    class="wameed-input text-reg-14 text-font-main"
    :class="fieldClasses"
    :label="label"
    :label-for="id"
  >
    <!--  <validation-provider
      #default="{ errors }"
      :name="name"
      :vid="name"
      :rules="rules"
    > -->
    <div
      class="multi-images-upload"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="drop($event)"
    >
      <div v-show="dropped == 2" class="drop" />
      <!-- Error Message -->
      <!-- <div v-show="error" class="error mb-4">
          {{ error }}
        </div> -->

      <!-- To inform user how to upload image -->
      <div class="beforeUpload">
        <input
          :id="id"
          ref="uploadInput"
          type="file"
          :name="id"
          style="z-index: 1"
          :accept="
            fileType === 'image'
              ? 'image/*'
              : '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'
          "
          @change="previewImgs($event, 'upload')"
        />
      </div>

      <div class="imgsPreview">
        <button v-if="false" type="button" class="clearButton" @click="reset">
          {{ clearAll ? clearAll : 'clear All' }}
        </button>

        <div class="imgsPreview__imgs">
          <div
            v-if="Imgs.length < max && fileType == 'image'"
            class="plus text-reg-12 text-center"
            @click="append"
          >
            <img-upload-icon />
            {{ title ? title : $t('common.upload_img') }}
          </div>
          <div
            v-if="Imgs.length < max && fileType == 'file'"
            class="plus text-reg-12 text-center"
            @click="append"
          >
            <file-upload-icon />

            {{ title ? title : $t('common.upload_img') }}
          </div>

          <div v-for="(img, i) in Imgs" :key="i" class="imageHolder">
            <img
              v-if="img && img.full_path && fileType != 'file'"
              :src="img.full_path + img.file_name"
              class="w-100 h-100"
            />
            <!-- <open-file-without-text-icon
              v-else
              class="w-100 h-100"
            /> -->
            <label v-else class="open-file-icon w-100 h-100">
              <span v-if="img.type == 'pdf'"> <pdf-icon /></span>
              <span v-else> <word-icon /></span>
              <div class="file-data">
                <p class="text-bold-12 text-font-main m-0">
                  {{ img.name ? img.name.split('_')[0] : img.name }}
                </p>
                <p class="text-bold-10 text-font-secondary m-0" dir="ltr">
                  {{ img.size }} MB
                </p>
              </div>
            </label>
            <span class="delete" @click="deleteImg(i)">
              <img-close-icon />
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
    <small v-show="error" class="text-danger">{{ error }}</small>
    <small v-if="errorField" class="text-warning">
      {{ $t('common.please_edit') }} {{ label }}</small
    >
    <!-- </validation-provider> -->
  </b-form-group>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'MultiUploadImages', // vue component name
  components: {
    ValidationProvider,
  },
  props: {
    min: Number,
    max: Number,
    uploadMsg: String,
    maxError: String,
    fileError: String,
    clearAll: String,
    label: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    fieldClasses: {
      type: String,
      default: null,
    },
    fileType: {
      type: String,
      default: 'image',
    },
    Imgs: {
      type: Array,
      default: () => [],
    },
    errorField: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      error: '',
      files: [],
      dropped: 0,
    };
  },
  methods: {
    ...mapActions({ uploadFiles: 'uploadFiles' }),
    dragOver() {
      this.dropped = 2;
    },
    dragLeave() {},
    drop(e) {
      let status = true;
      const files = Array.from(e.dataTransfer.files);
      if (e && files) {
        files.forEach((file) => {
          const fileTypes = [
            'application/pdf',
            '.doc',
            '.docx',
            'application/msword',
            'image/jpeg',
            'image/png',
            'image/jpg',
          ];
          const fileTypesDocs = [
            'application/pdf',
            '.doc',
            '.docx',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          ];
          if (
            (this.fileType == 'image' &&
              file.type.startsWith('image') === false) ||
            (this.fileType == 'file' && !fileTypesDocs.includes(files.type))
          ) {
            status = false;
          } else if (this.fileType == 'all' && !fileTypes.includes(file.type)) {
            status = false;
          }
        });
        if (status == true) {
          // if (this.max && files.length + this.files.length > this.max) {
          //   this.error = this.maxError
          //     ? this.$props.maxError
          //     : Maximum files is  + this.max;
          // } else {
          // this.files.push(...files);
          this.previewImgs(files, 'drag');
          // }
        } else {
          this.error = this.$props.fileError
            ? this.$props.fileError
            : 'Unsupported file type';
        }
      }
      this.dropped = 0;
    },
    append() {
      this.$refs.uploadInput.click();
    },
    readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = function () {
          resolve(fr.result);
        };
        fr.onerror = function () {
          reject(fr);
        };
        fr.readAsDataURL(file);
      });
    },
    deleteImg(index) {
      this.Imgs.splice(index, 1);
      this.files.splice(index, 1);
      this.$emit('changed', this.Imgs);
      this.$refs.uploadInput.value = null;
    },
    previewImgs(event, type) {
      let files = [];
      if (type == 'upload') {
        files = event.currentTarget.files;
      } else {
        files = event;
      }
      // if (this.dropped == 0) files=event;
      // else files=event.currentTarget.files
      this.error = '';
      const readers = [];

      if (!files.length) return;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        if (this.Imgs.length < this.max) {
          const fileTypes = [
            'application/pdf',
            '.doc',
            '.docx',
            'application/msword',
            'image/jpeg',
            'image/png',
            'image/jpg',
          ];
          const fileTypesDocs = [
            'application/pdf',
            '.doc',
            '.docx',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          ];
          if (
            (this.fileType == 'image' &&
              files[i].type.startsWith('image') === false) ||
            (this.fileType == 'file' && !fileTypesDocs.includes(files[i].type))
          ) {
            this.error = this.$props.fileError
              ? this.$props.fileError
              : 'Unsupported file type';
            return;
          }
          if (this.fileType == 'all' && !fileTypes.includes(files[i].type)) {
            this.error = this.$props.fileError
              ? this.$props.fileError
              : 'Unsupported file type';
            return;
          }
          formData.append('file', files[i]);
          // todo img push
          // this.Imgs.push('');
          this.uploadFiles(formData).then((response) => {
            this.Imgs = [];
            this.Imgs.push(response.content);
            this.$emit('changed', this.Imgs);
          });
          // readers.push(this.readAsDataURL(this.files[i]));
        } else {
          this.error = this.maxError
            ? this.maxError
            : `Maximum files is${this.max}`;
          return;
        }
      }
      // Promise.all(readers).then((values) => {
      //   this.Imgs = values;
      // });
    },
    reset() {
      this.$refs.uploadInput.value = null;
      this.Imgs = [];
      this.files = [];
      this.$emit('changed', this.Imgs);
    },
  },
};
</script>
