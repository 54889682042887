<template>
  <div>
    <div class="wameed-dashboard-page-content mb-5">
      <div>
        <page-header
          v-if="getOrderDetails"
          :title="
            getOrderDetails.status != 10
              ? $t('project_page.project_details')
              : $t('project_page.project_details_closed')
          "
          :sub-title="
            getOrderDetails.status != 10
              ? $t('project_page.project_subdetails')
              : $t('project_page.project_subdetails_closed')
          "
          :btn="getOrderDetails && getOrderDetails.offers_sent"
          variant="main"
          :btn-title="$t('common.log_offers')"
          classes="text-bold-15 text-white w-cus-sm-100"
          @btnAction="browserOffers"
        />
      </div>
      <div>
        <section class="wameed-dashboard-page-content_body">
          <div>
            <div
              v-if="getOrderDetails && getOrderDetails.status == 6"
              class="
                border-danger
                rounded-14
                bg-light-danger
                d-flex
                py-5
                px-5
                flex-wrap
              "
            >
              <div class="col-4 d-flex">
                <div class="pr-4 d-flex align-items-start">
                  <danger-icon />
                </div>
                <div class="d-flex flex-column">
                  <span class="text-font-main text-bold-16"
                    >{{ $t('project_page.project_canceled') }}
                  </span>
                  <p class="text-font-secondary text-bold-16">
                    {{ $t('project_page.project_canceled_subtitle') }}
                  </p>
                </div>
              </div>
              <div v-if="getOrderDetails" class="col-6 d-flex">
                <div class="d-flex flex-column">
                  <span class="text-font-main text-bold-16"
                    >{{ $t('project_page.cancel_reason') }}
                  </span>
                  <p class="text-font-secondary text-bold-16">
                    {{ getOrderDetails.cancel_reason }}
                  </p>
                </div>
              </div>
            </div>
            <project-statuses v-else :statuses="projectStatuses" />
            <b-row class="w-100 px-0 m-0">
              <b-col md="6" class="my-4">
                <b-row>
                  <b-col md="12" class="my-4">
                    <project-details-table
                      v-if="getOrderDetails"
                      :id="getOrderDetails.id"
                      :date="getOrderDetails.created_at"
                      :end-date="getOrderDetails.end_date"
                      :maturity-invoice="getOrderDetails.maturity_invoice"
                      :payment-invoice="getOrderDetails.payment_invoice"
                      :employee-invoice="getOrderDetails.employee_invoice"
                      :maturity-invoice-employee="
                        getOrderDetails.maturity_invoice_employee
                      "
                      :status="getOrderDetails.status"
                      :platform-dues="getOrderDetails.platform_dues"
                      :project-rate="getOrderDetails.project_rating"
                    />
                  </b-col>
                  <b-col md="12" class="my-4">
                    <order-details-table
                      v-if="getOrderDetails"
                      :id="getOrderDetails.id"
                      :title="getOrderDetails.hr_job_title"
                      :employee-job-title="getOrderDetails.employee_job_title"
                      :required-jobs-no="getOrderDetails.required_jobs_no"
                      :date="getOrderDetails.created_at"
                      :description="getOrderDetails.description"
                      :industry-preference="
                        getOrderDetails.industry_preference.name
                      "
                      :file="getOrderDetails.file"
                      :role-preference="getOrderDetails.role_preference"
                      :services="getOrderDetails.services"
                      :status="getOrderDetails.status"
                    />
                  </b-col>
                </b-row>
              </b-col>

              <b-col
                v-if="getOrderDetails && getOrderDetails.offers"
                md="6"
                class="my-4 pl-md-0"
              >
                <b-row class="w-100 m-0">
                  <b-col md="12" class="my-4 px-0">
                    <offer-details-table
                      :id="getOrderDetails.offers.id"
                      :price="getOrderDetails.offers.price"
                      :description="getOrderDetails.offers.description"
                      :date="getOrderDetails.offers.created_at"
                      :file="getOrderDetails.offers.file"
                    />
                  </b-col>
                  <b-col md="12" class="my-4 px-0">
                    <project-employer-details-table
                      v-if="getOrderDetails && getOrderDetails.seeker_data"
                      :id="getOrderDetails.seeker_data.id"
                      :name="getOrderDetails.seeker_data.name"
                      :photo="getOrderDetails.seeker_data.photo"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import OrderDetailsTable from '@/components/pages/orders/OrderDetailsTable.vue';
import PageHeader from '@/components/PageHeader.vue';
import OrdersCard from '@/components/pages/orders/OrdersCard.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import SetpsWizard from '@/components/steps-wizard/WizardNumber.vue';
import OfferDetailsTable from '@/components/pages/orders/OfferDetailsTable.vue';
import ProjectDetailsTable from '@/components/pages/orders/ProjectDetailsTable.vue';
import ProjectEmployerDetailsTable from '@/components/pages/orders/ProjectEmployerDetailsTable.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import FilterHeader from '@/components/FilterHeader.vue';
import WameedPagination from '@/components/WameedPagination.vue';
import offersCard from '@/components/pages/orders/OffersCard.vue';
import projectStatuses from '@/components/pages/orders/projectStatuses.vue';

export default {
  components: {
    OrderDetailsTable,
    PageHeader,
    OrdersCard,
    // WameedBtn,
    SetpsWizard,
    OfferDetailsTable,
    ProjectDetailsTable,
    ProjectEmployerDetailsTable,
    WameedNoData,
    FilterHeader,
    WameedPagination,
    offersCard,
    projectStatuses,
  },
  data() {
    return {
      projectStatuses: [
        {
          active: false,
          checked: false,
          tabId: 1,
          title: this.$i18n.t('project_page.on_progress'),
          subtitle: this.$i18n.t('project_page.on_progress_subititle'),
          status: 5,
        },
        {
          active: false,
          checked: false,
          tabId: 2,
          title: this.$i18n.t('common.project_close_request'),
          subtitle: this.$i18n.t('common.project_close_request_subititle'),
          status: 10,
        },
        {
          active: false,
          checked: false,
          tabId: 3,
          title: this.$i18n.t('common.project_sent_by_employee'),
          subtitle: this.$i18n.t('common.project_sent_by_employee_subititle'),
          status: 11,
        },
        {
          active: false,
          checked: false,
          tabId: 4,
          title: this.$i18n.t('common.project_pending_2'),
          subtitle: this.$i18n.t('common.project_pending_2_subititle'),
          status: 7,
        },
        {
          active: false,
          checked: false,
          tabId: 5,
          title: this.$i18n.t('common.payment_review'),
          subtitle: this.$i18n.t('common.payment_review_subititle'),
          status: 8,
        },
        {
          active: false,
          checked: false,
          tabId: 6,
          title: this.$i18n.t('common.project_closed'),
          subtitle: this.$i18n.t('common.project_closed_subititle'),
          status: 9,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getOrderDetails: 'getOrderDetails',
    }),
  },
  watch: {
    getOrderDetails(data) {
      for (let index = 0; index < this.projectStatuses.length; index++) {
        const element = this.projectStatuses[index];
        let newList = [];
        if (data.status === element.status) {
          const initial = (arr) => arr.slice(0, index);
          element.active = true;
          element.checked = false;
          newList = initial(this.projectStatuses);
          newList.map((item) => {
            item.active = false;
            item.checked = true;
          });
        }
      }
    },
  },
  mounted() {
    this.loadOrdersDetails(this.$router.currentRoute.params.id);
  },

  methods: {
    ...mapActions({
      loadOrdersDetails: 'loadOrdersDetails',
    }),
    loadData() {},
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
    browserOffers() {
      this.$router.push({
        name: 'offers-for-project',
        params: {
          lang: this.$i18n.locale,
        },
      });
    },
  },
};
</script>
