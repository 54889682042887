<template>
  <!-- <b-table bordered borderless stacked :items="items"></b-table> -->
  <div class="package-details-table bg-white overflow-hidden rounded-14">
    <b-row class="">
      <b-col sm="12" class="package-details-name">
        <h1 class="package-details-table__title text-font-main text-bold-16">
          {{ $t('project_page.project_data') }}
        </h1>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="5" class="package-details-name">
        <p class="mb-0">{{ $t('project_page.project_no') }}:</p>
      </b-col>
      <b-col sm="7" class="package-details-value">
        <p class="mb-0">#{{ id }}</p>
      </b-col>
    </b-row>
    <!-- <b-row class="package-details-table__body">
      <b-col sm="5" class="package-details-name">
        <p class="mb-0">{{ $t('orders_page.order_status') }}:</p>
      </b-col>
      <b-col sm="7" class="package-details-value">
        <div class="d-flex">
          <b-badge
            pill
            :variant="'light-' + statusList[1][status]"
            :class="'border-' + statusList[1][status]"
            class="text-reg-12 py-0 px-3"
            >{{ $t('common.' + statusList[0][status]) }}</b-badge
          >
        </div>
      </b-col>
    </b-row> -->
    <b-row class="package-details-table__body">
      <b-col sm="5" class="package-details-name">
        <p class="mb-0">{{ $t('project_page.start_date') }}:</p>
      </b-col>
      <b-col sm="7" class="package-details-value">
        <p class="mb-0">
          {{ formatedDate(date) }}
        </p>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="5" class="package-details-name">
        <p class="mb-0">{{ $t('project_page.end_date') }}:</p>
      </b-col>
      <b-col sm="7" class="package-details-value">
        <p v-if="endDate" class="mb-0">
          {{ formatedDate(endDate) }}
        </p>
        <p v-else class="mb-0">---</p>
      </b-col>
    </b-row>
    <b-row
      v-if="status == 7 || status == 8 || status == 9 || status == 11"
      class="package-details-table__body"
    >
      <b-col sm="5" class="package-details-name">
        <p class="mb-0">{{ $t('project_page.maturity_invoice_remployee') }}:</p>
      </b-col>
      <b-col sm="7" class="package-details-value">
        <div class="d-flex flex-wrap comment-info--images mt-0" dir="rtl">
          <label
            v-if="maturityInvoiceEmployee && maturityInvoiceEmployee.file_name"
            class="open-file-icon"
            @click="openFile(maturityInvoiceEmployee)"
          >
            <span v-if="maturityInvoiceEmployee.type == 'pdf'">
              <pdf-icon
            /></span>
            <span v-else> <word-icon /></span>
            <div class="file-data">
              <p class="text-bold-12 text-font-main m-0">
                {{
                  maturityInvoiceEmployee.name
                    ? maturityInvoiceEmployee.name.split('_')[0]
                    : maturityInvoiceEmployee.name
                }}
              </p>
              <p class="text-bold-10 text-font-secondary m-0" dir="ltr">
                {{ maturityInvoiceEmployee.size }} MB
              </p>
            </div>
            <!-- <open-file-icon /> -->
          </label>
        </div>
      </b-col>
    </b-row>
    <b-row
      v-if="status == 7 || status == 8 || status == 9 || status == 11"
      class="package-details-table__body"
    >
      <b-col sm="5" class="package-details-name">
        <p class="mb-0">{{ $t('project_page.employee_rating_project') }}:</p>
      </b-col>
      <b-col sm="7" class="package-details-value">
        <div class="d-block flex-wrap comment-info--images mt-0">
          <b-badge
            v-if="projectRate && projectRate.stars"
            pill
            :variant="'light-warning'"
            :class="'border-warning'"
            class="text-reg-16 py-0 px-3"
            style="width: fit-content"
          >
            <review-stars-2-icon
              style="width: inherit; height: inherit"
              class="mr-2"
            />
            {{ projectRate.stars }}/5</b-badge
          >
          <br />
          <p v-if="projectRate && projectRate.stars" class="mb-0">
            {{ projectRate.comment }}
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row
      v-if="status == 7 || status == 8 || status == 9 || status == 11"
      class="package-details-table__body"
    >
      <b-col sm="5" class="package-details-name">
        <p class="mb-0">{{ $t('project_page.maturity_invoice') }}:</p>
      </b-col>
      <b-col sm="7" class="package-details-value">
        <div
          v-if="maturityInvoice && maturityInvoice.file_name"
          class="d-flex flex-wrap comment-info--images mt-0"
          dir="rtl"
        >
          <label class="open-file-icon" @click="openFile(maturityInvoice)">
            <span v-if="maturityInvoice.type == 'pdf'"> <pdf-icon /></span>
            <span v-else> <word-icon /></span>
            <div class="file-data">
              <p class="text-bold-12 text-font-main m-0">
                {{
                  maturityInvoice.name
                    ? maturityInvoice.name.split('_')[0]
                    : maturityInvoice.name
                }}
              </p>
              <p class="text-bold-10 text-font-secondary m-0" dir="ltr">
                {{ maturityInvoice.size }} MB
              </p>
            </div>
            <!-- <open-file-icon /> -->
          </label>
        </div>
        <multi-images-upload
          v-else
          id="file"
          :max="1"
          clear-all=""
          label=""
          name="file"
          rules="required"
          file-type="file"
          @changed="updateMaturityInvoice"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="status == 8 || status == 9"
      class="package-details-table__body"
    >
      <b-col sm="5" class="package-details-name">
        <p class="mb-0">
          {{ $t('project_page.payment_invoice_from_company') }}:
        </p>
      </b-col>
      <b-col sm="7" class="package-details-value">
        <div class="d-flex flex-wrap comment-info--images mt-0" dir="rtl">
          <label
            v-if="paymentInvoice && paymentInvoice.file_name"
            class="open-file-icon"
            @click="openFile(paymentInvoice)"
          >
            <span v-if="paymentInvoice.type == 'pdf'"> <pdf-icon /></span>
            <span v-else> <word-icon /></span>
            <div class="file-data">
              <p class="text-bold-12 text-font-main m-0">
                {{
                  paymentInvoice.name
                    ? paymentInvoice.name.split('_')[0]
                    : paymentInvoice.name
                }}
              </p>
              <p class="text-bold-10 text-font-secondary m-0" dir="ltr">
                {{ paymentInvoice.size }} MB
              </p>
            </div>
            <!-- <open-file-icon /> -->
          </label>
        </div>
      </b-col>
    </b-row>
    <b-row
      v-if="status == 8 || status == 9"
      class="package-details-table__body"
    >
      <b-col sm="5" class="package-details-name">
        <p class="mb-0">
          {{ $t('project_page.transfer_amount_to_employee') }}:
        </p>
      </b-col>
      <b-col sm="7" class="package-details-value">
        <div
          v-if="employeeInvoice && employeeInvoice.file_name"
          class="d-flex flex-wrap comment-info--images mt-0"
          dir="rtl"
        >
          <label class="open-file-icon" @click="openFile(employeeInvoice)">
            <span v-if="employeeInvoice.type == 'pdf'"> <pdf-icon /></span>
            <span v-else> <word-icon /></span>
            <div class="file-data">
              <p class="text-bold-12 text-font-main m-0">
                {{
                  employeeInvoice.name
                    ? employeeInvoice.name.split('_')[0]
                    : employeeInvoice.name
                }}
              </p>
              <p class="text-bold-10 text-font-secondary m-0" dir="ltr">
                {{ employeeInvoice.size }} MB
              </p>
            </div>
            <!-- <open-file-icon /> -->
          </label>
        </div>
        <multi-images-upload
          v-else
          id="file"
          :max="1"
          clear-all=""
          label=""
          name="file"
          rules="required"
          file-type="file"
          @changed="updateEmployeeInvoice"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="status == 8 || status == 9"
      class="package-details-table__body"
    >
      <b-col sm="5" class="package-details-name">
        <p class="mb-0">{{ $t('common.platform_dues') }}:</p>
      </b-col>
      <b-col sm="7" class="package-details-value">
        <div class="d-flex flex-wrap comment-info--images mt-0">
          <p class="mb-0">% {{ platformDues }}</p>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="status == 11" class="">
      <b-col sm="12" class="package-details-name py-5 d-flex p-0-30">
        <wameed-btn
          classes="text-med-14 text-white rounded-10 mx-5"
          :title="$t('project_page.send_invoice')"
          type="button"
          variant="main"
          :disabled="maturity_invoice_file.length <= 0 ? true : false"
          @submitAction="showSendInvoice = true"
        />
        <wameed-btn
          classes="ml-auto mr-5 text-med-14 text-font-secondary rounded-10 "
          :title="$t('common.cancel')"
          type="button"
          variant="gray"
          @submitAction="cancelOrder"
        />
      </b-col>
      <warning-modal
        variant="main"
        variant2="main"
        icon-name="send-icon"
        :visible="showSendInvoice"
        :title="$t('project_page.activation_send_invoice')"
        :sub-title="$t('project_page.activation_send_invoice_subdetails')"
        :btn-title="$t('common.send')"
        @close="showSendInvoice = false"
        @submitAction="sendInvoice()"
      />
    </b-row>
    <b-row v-if="status == 8" class="">
      <b-col sm="12" class="package-details-name py-5 d-flex">
        <wameed-btn
          classes="text-med-14 text-white rounded-10 mx-5"
          :title="$t('project_page.close_project')"
          type="button"
          variant="main"
          :disabled="employee_invoice.length <= 0 ? true : false"
          @submitAction="closeProjectModal = true"
        />
        <wameed-btn
          classes="ml-auto mr-5 text-med-14 text-font-secondary rounded-10 "
          :title="$t('common.cancel')"
          type="button"
          variant="gray"
          onclick="history.back()"
        />
      </b-col>
      <warning-modal
        variant="main"
        variant2="main"
        icon-name="project-closing-requests-icon"
        :visible="closeProjectModal"
        :title="$t('project_page.confirm_close_project')"
        :sub-title="$t('project_page.confirm_close_project_subtitle')"
        :btn-title="$t('project_page.close_project')"
        @close="closeProjectModal = false"
        @submitAction="closeProject()"
      />
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MultiImagesUpload from '@/components/multiImagesUpload.vue';
import WarningModal from '@/components/WarningModal.vue';
// import WameedForm from '@/components/WameedForm.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import { double } from 'vee-validate/dist/rules';
// import TextArea from '../../TextArea.vue';
export default {
  components: {
    MultiImagesUpload,
    WarningModal,
    WameedBtn,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    status: {
      type: Number,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    endDate: {
      type: String,
      default: null,
    },
    maturityInvoice: {
      type: Object,
      default: null,
    },
    paymentInvoice: {
      type: Object,
      default: null,
    },
    maturityInvoiceEmployee: {
      type: Object,
      default: null,
    },
    employeeInvoice: {
      type: Object,
      default: null,
    },
    platformDues: {
      type: Number,
      default: null,
    },
    projectRate: {
      type: Number,
      default: null,
    },
    badge: {
      type: Object,
      default() {
        return {
          name: 'مقبول',
          color: 'success',
        };
      },
    },
  },
  data() {
    return {
      maturity_invoice_file: [],
      payment_invoice: [],
      employee_invoice: [],
      showSendInvoice: false,
      closeProjectModal: false,
      statusList: [
        {
          0: 'project_rejected',
          1: 'project_active',
          2: 'project_before_paying',
          3: 'project_under_review',
          4: 'project_offer_selection',
          5: 'project_agreed',
          6: 'project_canceled',
          7: 'project_pending',
          8: 'project_finished_employee_not_paid',
          9: 'project_finished',
          10: 'project_close_request',
          11: 'project_sent_by_employee',
          12: 'project_request_cancel',
        },
        {
          0: 'danger',
          1: 'warning',
          2: 'info',
          3: 'icon',
          4: 'success',
          5: 'warning',
          6: 'danger',
          7: 'icon',
          8: 'warning',
          9: 'success',
          10: 'icon',
          11: 'success',
          12: 'warning',
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      changeOrdersStatus: 'changeOrdersStatus',
    }),
    openFile(data) {
      window.open(data.full_path + data.file_name, '_blank');
    },
    updateMaturityInvoice(images) {
      this.maturity_invoice_file = images;
    },
    updateEmployeeInvoice(images) {
      this.employee_invoice = images;
    },
    updateImages(images) {
      this.payment_invoice = images;
    },
    cancelOrder() {
      this.$router.push({
        name: 'projects',
        params: { lang: this.$i18n.locale },
      });
    },
    sendInvoice() {
      this.changeOrdersStatus({
        id: this.$router.currentRoute.params.id,
        status: 7,
        maturity_invoice: this.maturity_invoice_file
          ? this.maturity_invoice_file[0].file_name
          : null,
      }).then(() => {
        this.showSendInvoice = false;
      });
    },
    closeProject() {
      this.changeOrdersStatus({
        id: this.$router.currentRoute.params.id,
        status: 9,
        employee_invoice: this.employee_invoice
          ? this.employee_invoice[0].file_name
          : null,
      }).then(() => {
        this.showSendInvoice = false;
      });
    },
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
  },
};
</script>
