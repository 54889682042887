<template>
  <div class="vue-form-wizard pb-0 md">
    <div class="wizard-navigation">
      <div class="wizard-progress-with-circle">
        <div
          class="wizard-progress-bar bg-success text-success"
          :style="
            false ? 'width: 0%; ' : false ? 'width: 50%;' : 'width: 100%;'
          "
        />
      </div>
      <ul
        role="tablist"
        class="wizard-nav wizard-nav-pills"
      >
        <li
          v-for="(item, i) in items"
          :key="i"
        >
          <a
            class="step-item d-flex flex-column"
          ><div
             :id="'step' + id"
             role="tab"
             tabindex="0"
             :aria-selected="item.active"
             class="wizard-icon-circle md bg-white"
             :class="{
               'border-success': item.active || item.checked,
               'border-disabled': item.disabled,
             }"
           >
             <div
               class="w-100 h-100 wizard-icon-circle ma-0"
               :class="{
                 'bg-light-success': item.active || item.checked,
                 'bg-white': item.disabled,
                 'border-white': item.disabled,
                 'border-success': item.checked,
               }"
             >
               <div
                 v-if="item.active"
                 class="wizard-icon-container w-100 h-100"
               >
                 <span class="text-reg-12 text-center">{{ i + 1 }}</span>
               </div>
               <i
                 v-if="item.checked"
                 class="wizard-icon w-100 h-100"
               >
                 <tick-icon class="svg-success" />
               </i>
               <div
                 v-if="item.disabled"
                 class="wizard-icon w-100 h-100"
               >
                 <span class="text-reg-12 text-center">{{ i + 1 }}</span>
               </div>
             </div>
           </div>
            <span
              class="text-bold-16"
              :class="item.disabled ? 'text-font-secondary' : 'text-font-main'"
            >
              {{ item.title }}
            </span>
            <span
              class="text-bold-16"
              :class="item.disabled ? 'text-input' : 'text-font-secondary'"
            >
              {{ item.subtitle }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import "@/assets/scss/wizard.scss";
// import 'vue-form-wizard/dist/vue-form-wizard.min.css';
export default {
  components: {
  },
  props: {
    items: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
    };
  },

  methods: {

  },
};
</script>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
