var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"py-0 px-3 project-statuses mb-0"},_vm._l((_vm.statuses),function(item,index){return _c('b-col',{key:index,staticClass:"project-statuses-item w-100",class:{
      'item-active': item.active,
      'item-checked': item.checked,
    },attrs:{"lg":"2"}},[(
        _vm.mobileScreenSize && _vm.statuses[index - 1] && _vm.statuses[index - 1].checked
      )?_c('div',{staticClass:"list-checked"},[_c('span',{staticClass:"font-bold"})]):_vm._e(),(
        (_vm.mobileScreenSize &&
          _vm.statuses[index - 1] &&
          _vm.statuses[index - 1].checked) ||
        _vm.statuses.slice(-1)[0].tabId == item.tabId
      )?_c('label'):_vm._e(),_c('div',{staticClass:"d-flex align-items-start"},[_c('div',[_c('span',{staticClass:"font-bold"},[(!item.checked)?[_vm._v(" "+_vm._s(item.tabId)+" ")]:_vm._e()],2)]),_c('div',{staticClass:"ml-3"},[_c('h3',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"font-med"},[_vm._v(" "+_vm._s(item.subtitle)+" ")])])]),(_vm.statuses.slice(-1)[0].tabId !== item.tabId)?_c('label'):_vm._e(),(_vm.mobileScreenSize)?_c('div',{staticClass:"list-display"},[(
          _vm.statuses.slice(-1)[0].tabId !== _vm.statuses[index].tabId + 1 &&
          _vm.statuses.slice(-1)[0].tabId !== item.tabId
        )?_c('span',{staticClass:"font-bold"},[(!item.checked)?[_vm._v(" "+_vm._s(_vm.statuses[index].tabId + 1)+" ")]:_vm._e()],2):_vm._e(),(
          _vm.statuses.slice(-1)[0].tabId !== _vm.statuses[index].tabId + 1 &&
          _vm.statuses.slice(-1)[0].tabId !== item.tabId
        )?_c('p',{staticClass:"my-0 mx-2"},[(_vm.statuses.slice(-1)[0].tabId - 2 !== item.tabId)?[_vm._v(" ... ")]:_vm._e()],2):_vm._e(),(_vm.statuses.slice(-1)[0].tabId !== item.tabId)?_c('span',{staticClass:"font-bold"},[(!item.checked)?[_vm._v(" "+_vm._s(_vm.statuses.slice(-1)[0].tabId)+" ")]:_vm._e()],2):_vm._e()]):_vm._e()])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }