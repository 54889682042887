<template>
  <b-row class="py-0 px-3 project-statuses mb-0">
    <b-col
      v-for="(item, index) in statuses"
      :key="index"
      lg="2"
      class="project-statuses-item w-100"
      :class="{
        'item-active': item.active,
        'item-checked': item.checked,
      }"
    >
      <div
        v-if="
          mobileScreenSize && statuses[index - 1] && statuses[index - 1].checked
        "
        class="list-checked"
      >
        <span class="font-bold" />
      </div>
      <label
        v-if="
          (mobileScreenSize &&
            statuses[index - 1] &&
            statuses[index - 1].checked) ||
          statuses.slice(-1)[0].tabId == item.tabId
        "
      />
      <div class="d-flex align-items-start">
        <div>
          <span class="font-bold"
            ><template v-if="!item.checked">
              {{ item.tabId }}
            </template></span
          >
        </div>
        <div class="ml-3">
          <h3 class="font-bold">
            {{ item.title }}
          </h3>
          <p class="font-med">
            {{ item.subtitle }}
          </p>
        </div>
      </div>
      <label v-if="statuses.slice(-1)[0].tabId !== item.tabId" />
      <div v-if="mobileScreenSize" class="list-display">
        <span
          v-if="
            statuses.slice(-1)[0].tabId !== statuses[index].tabId + 1 &&
            statuses.slice(-1)[0].tabId !== item.tabId
          "
          class="font-bold"
          ><template v-if="!item.checked">
            {{ statuses[index].tabId + 1 }}
          </template></span
        >
        <p
          v-if="
            statuses.slice(-1)[0].tabId !== statuses[index].tabId + 1 &&
            statuses.slice(-1)[0].tabId !== item.tabId
          "
          class="my-0 mx-2"
        >
          <template v-if="statuses.slice(-1)[0].tabId - 2 !== item.tabId">
            ...
          </template>
        </p>
        <span
          v-if="statuses.slice(-1)[0].tabId !== item.tabId"
          class="font-bold"
          ><template v-if="!item.checked">
            {{ statuses.slice(-1)[0].tabId }}
          </template></span
        >
      </div>
    </b-col></b-row
  >
</template>
<script>
export default {
  props: {
    statuses: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      mobileScreenSize: false,
    };
  },
  mounted() {
    this.windowWidthFunction();
  },
  methods: {
    windowWidthFunction() {
      if (window.innerWidth <= 960) {
        this.mobileScreenSize = true;
      }
    },
  },
};
</script>
